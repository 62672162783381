/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.$http = axios;

window.$http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

declare global {
  const $http: typeof axios;

  interface Window {
    $http: typeof axios;
  }
}
